import { render, staticRenderFns } from "./FormationCouncilsDetail.vue?vue&type=template&id=0673968a&scoped=true&"
import script from "./FormationCouncilsDetail.vue?vue&type=script&lang=js&"
export * from "./FormationCouncilsDetail.vue?vue&type=script&lang=js&"
import style0 from "./FormationCouncilsDetail.vue?vue&type=style&index=0&id=0673968a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0673968a",
  null
  
)

export default component.exports