<template>
  <div>
    <a class="come-back" @click="$router.go(-1)"><div></div>Вернуться назад</a>
    <div class="two-rows-wrapper">
      <main>
        <div class="main-project">
          <p class="main-project__date">{{ dateArr(detailPages.date) }}</p>
          <article>
            <header>
              <h1>{{ detailPages.title }}</h1>
            </header>
            <div v-html="detailPages.text" class="main-project__text ckEditor-html"></div>
          </article>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormationCouncilsDetail',
  data () {
    return {
      detailPages: ''
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).slice(0, -3))
    }
  },
  async mounted () {
    let res = await fetch(`${this.$store.getters.getUrlApi}api/сommunity-сouncils/${this.$route.params.id}`)
    res = await res.json()
    this.detailPages = await res.data
    document.title = await res.data.title
  }
}

</script>

<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
  main {
    background: none;
    padding: 0;
    border-radius: 0;
  }

  .main-project {
    font-size: 0.875rem;
    background: white;
    padding: 2.5rem 3.125rem;
    border-radius: 8px;

    &__date {
      color: #5E686C;
      opacity: 0.8;
      line-height: 1.57;
      margin-bottom: 1.25rem;
    }

    article {
      text-align: left;
      margin-bottom: 3.125rem;
    }

    ::v-deep &__text {

      :nth-child(n) {
        margin-bottom: 2.375rem;
        line-height: 157%;
        color: rgba(23, 23, 23, 0.8);
      }

    }

  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.27;
    color: #1F3245;
    margin-bottom: 2rem;
  }

  .two-rows-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.875rem 3.125rem 1.875rem 1.875rem;
  }

  /*стрелка назад*/

  .come-back {
    /*display: block;*/
    width: 84.37rem;
    margin: .875rem auto;
    font-size: .75rem;
    line-height: 1.38rem;
    display: flex;
    justify-content: flex-start;
    color: #246CB7;
    cursor: pointer;
  }

  .come-back div {
    position: relative;
    top: .625rem;
    width: .75rem;
    height: .0925rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    /*left:22em;*/
    display: block;
    border-radius: .820075px;
    margin-right: .4375rem;
  }

  .come-back div::after {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    top: -.23rem;
    left: .11rem;
    background-color: #337AB7;
    transform: rotate(-135deg);
    border-radius: .820075px;
  }

  .come-back div::before {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    /*top: 11px;*/
    left: .11rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(135deg);
    border-radius: .820075px;
  }

  @media screen and (max-width: 768px) {
    .come-back {
      width: 90%;
    }
  }

  @media screen and (max-width: 420px) {
    .main-project {
      width: 100%;
      margin: 0;
      padding: 1.88rem 1rem;
    }
  }

</style>
